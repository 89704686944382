.topHeaderSec {
    background:  #d2a616;
    padding: 5px;
}
.topHeaderInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.freeReturn p{
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.languageOption {
    display: flex;
    align-items: center;
    column-gap: 15px;
}
.rightList {
    display: flex;
    align-items: center;
    column-gap: 30px;
}
.pageLinks ul, .socialMedia ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.socialMedia ul {
    column-gap: 15px;
    /* height: 100%; */

}
.pageLinks ul li a {
    color: #fff;
    display: inline-block;
    padding: 3px 8px;
    font-size: 14px;
}
.socialMedia ul li a{
    color: #fff;
}
.optionSelect {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.lang select, .rupees select {
    background: transparent;
    color: #fff;
    align-items: center;
    display: flex;
    border: 0;
    font-size: 14px;
}
.countrySelect{
    background-color: #d2a616;
    color: #fff;
    border: none;
}
.countrySelect {
    outline: none;
}
/* .lang select option, .rupees select option{
    color: #111;
} */
.miscel {
    display: flex;
    column-gap: 5px;
}
.socialMedia ul li a.userNameText {
    font-size: 15px;
    text-transform: capitalize;
}
select.form-select.countrySelect {
    width: 160px;
    color: #ffffff;
    background:  #d2a616;
    border: 0;
    position: relative;
}
select.form-select.countrySelect:after {
    content: "a";
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    color: #fff;
}

@media (max-width: 991px){
    .pageLinks ul li a{
        font-size: 12px;
    }
}
.lang select{
    width: 100px;
    color: #ffffff;
    background:  #d2a616;
    border: 0;
    position: relative;
}




.indust{
    position: absolute;
    background-color: white;
    padding: 10px;
    top: 35px;
    display: none;
    left: 0;
    color: black;
    width: 230px;
  }
  .indusper{
    position: relative;
  }
  .indusper:hover .indust{
    display: block;
  }

  .indust ul li{
    list-style: none;
    padding: 10px 0;
  }
  .indust ul li:hover{
    background-color: #d2a616;
  }
  .indust ul li a {
    margin: 0 5px;
  }
  .indust ul li:hover a{
    color: white !important;
  }